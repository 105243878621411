import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';

import React from 'react';
import { Card, Row } from 'react-bootstrap';
import Slider from 'react-slick';

import content from '../../../content.json';
const { giftcards } = content;

const Info = () => (
    <Row className="info">
        <p className="title">{giftcards.info.title}</p>
        <Slider
            className="info-carousel"
            dots={false}
            infinite={true}
            centerMode={true}
            centerPadding="20px"
            slidesToShow={3}
            slidesToScroll={1}
            autoplay={false}
            responsive={[
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]}>
            {giftcards.info.content.map((content) => (
                <Card key={content.title}>
                    <Card.Body>
                        <Card.Title>{content.title}</Card.Title>
                        <Card.Text>{content.text}</Card.Text>
                    </Card.Body>
                </Card>
            ))}
        </Slider>
    </Row>
);

export default Info;
