import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useWithDispatch } from '../../hooks';
import { useStylistsStore } from '../../redux/reducers/stylists';
import { Style } from '../../services';
import {
    MP_EVENTS,
    MP_PROPS,
    MP_VALUES,
    registerProperties,
    trackEvent
} from '../../services/mixpanel';
import { setMixpanelStore } from '../../store/common/actions';
import { toggleModal } from '../../store/ui/actions';
import { updateUser } from '../../store/user/actions';
import { useUserStore } from '../../store/user/reducer';
import { TOnboardingState, TStyleId } from './types';

export const useOnboardingComplete = () => {
    const isCompletedOnce = useRef(false);
    const deferredCompleteState = useRef<TOnboardingState>();
    const { user } = useUserStore((store) => store);
    const { stylist } = useStylistsStore((store: unknown) => store);
    const toggleModalAction = useWithDispatch(toggleModal);
    const updateUserAction = useWithDispatch(updateUser);
    const setMixpanelStoreAction = useWithDispatch(setMixpanelStore);
    const history = useHistory();
    const ldUser = useLDClient()?.getUser();
    const ldFlags = useFlags();
    const isIdentified = user?.user_uuid; //&& ldUser && !ldUser.anonymous;

    // This function will run once on completion of onboarding
    // This is to prevent consecutive reporting on possible history.back or deferred completion
    const trackCompletionEvents = useCallback(
        (styles: [TStyleId, number][]) => {
            if (!isCompletedOnce.current && styles.length) {
                const stylePreferredList = styles
                    .filter(([, value]) => value === 2)
                    .map(([name]) => name);

                setMixpanelStoreAction({
                    onboarding: { [MP_PROPS.PREFERRED_STYLES]: stylePreferredList }
                });

                trackEvent({
                    name: MP_EVENTS.ONBOARDING_BRANDS_SELECTION,
                    properties: {
                        [MP_PROPS.PREFERRED_STYLES]: stylePreferredList
                    }
                });
                trackEvent({
                    name: MP_EVENTS.MEET_MY_MATCH,
                    properties: { [MP_PROPS.PREFERRED_STYLES]: stylePreferredList }
                });

                isCompletedOnce.current = true;
            }
        },
        [setMixpanelStoreAction]
    );

    const getRedirectUrl = useCallback(async () => {
        let result = '/';
        const userId = user?.user_uuid;

        if (userId) {
            // This flow is for the Stylist page -> [Book Stylist] -> Onboarding
            // Stylist is set by booking a stylist.
            if (stylist?.uuid) {
                if (stylist.has_active_session) {
                    result = `/chat/${stylist.uuid}/${userId}`;
                } else {
                    result = '/goals';
                }
            } else {
                let variant = 'control';
                result = '/stylistSearch';

                if (ldFlags['skipStylistMatch']) {
                    const { data: matchesData } = await Style.getMatches(userId);
                    const matches = (matchesData?.items || []) as {
                        match_percentage: number;
                        uuid: string;
                    }[];
                    const bestMatch = matches
                        .sort((a, b) => b.match_percentage - a.match_percentage)
                        .shift();

                    if (bestMatch) {
                        result = `/stylist/${bestMatch.uuid}/profile`;
                        variant = 'stylist_profile';
                    } else {
                        variant = 'search';
                    }
                }

                registerProperties({
                    [MP_PROPS.EXPERIMENT_NAME]: MP_VALUES.EXP_DIRECT_MATCH,
                    [MP_PROPS.EXPERIMENT_VARIANT]: variant
                });
                trackEvent({ name: MP_EVENTS.EXPERIMENT_STARTED });
            }
        }

        return result;
    }, [user, ldFlags, stylist]);

    const complete = useCallback(
        async (onboardingState: TOnboardingState) => {
            const userId = user?.user_uuid;
            const { gender, bodyTypes, styles: stylesMap, source } = onboardingState;
            const styles = [...(stylesMap || [])];

            trackCompletionEvents(styles);

            if (isIdentified) {
                const tags = [...(bodyTypes?.values() || [])].map(({ uuid, name }) => ({
                    tag_uuid: uuid,
                    is_set: 1,
                    name
                }));

                await Style.updateStyle(userId, {
                    gender,
                    tag_uuids: tags,
                    styles: styles.map(([name, rate]) => ({ name, rate }))
                });

                updateUserAction({ gender }); // This is to allow gendered stylist matches without re-fetching the user
                history.push(await getRedirectUrl());
            } else {
                deferredCompleteState.current = onboardingState; // Save the completed onboarding state for when the user is available

                toggleModalAction({
                    type: 'Unlock',
                    data: { source }
                });
            }
        },
        [
            getRedirectUrl,
            history,
            isIdentified,
            toggleModalAction,
            trackCompletionEvents,
            updateUserAction,
            user
        ]
    );

    useEffect(() => {
        if (deferredCompleteState.current && isIdentified) {
            const deferredState = { ...deferredCompleteState.current };
            deferredCompleteState.current = undefined;
            complete(deferredState);
        }
    }, [complete, isIdentified]);

    return complete;
};
