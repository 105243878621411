import mixpanel, { Dict } from 'mixpanel-browser';
import { klaviyoIdentify } from 'services/Klaviyo';
import { Stylist } from 'types/user';
import { Client } from 'types/user';

import { MP_EVENTS } from './consts';
import { extractUserDataForMixpanelPeople } from './utils';

const detectDeviceType = (width: number) => {
    if (width > 992) return 'desktop';
    else if (width < 992 && width > 567) return 'tablet';
    else return 'mobile';
};

export const initMixpanel = () => {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
            debug: process.env.NODE_ENV !== 'production'
        });
        registerProperties({
            Platform: 'web',
            Device: detectDeviceType(window.innerWidth)
        });
        trackEvent({ name: MP_EVENTS.WEBSITE_VISITS });
    }
};

export const trackEvent = (eventDetails: { name: MP_EVENTS; properties?: Dict }) =>
    mixpanel.track(eventDetails.name, eventDetails.properties);

export const registerProperties = (properties: Dict) => mixpanel.register(properties);

export const isMatchedStylist = (stylists: Stylist[], stylistUUID: string) =>
    !!stylists.find(({ uuid }) => uuid === stylistUUID);

export const setMixpanelPeopleData = (userData: Client) => {
    const currentPeopleData = extractUserDataForMixpanelPeople(userData);
    const uuid = currentPeopleData.UUID;

    if (uuid && mixpanel.get_distinct_id() !== uuid) {
        mixpanel.identify(uuid);
        mixpanel.people.set(currentPeopleData);
        // not the best place to place klaviyo tracking
        // it is becuse this method called in a lot of places.
        // first there should be a consolidation of that call
        // to a single plance and then klaviyo can be moved outside of it
        klaviyoIdentify(uuid, currentPeopleData.Email ?? undefined);
    }
};

export const resetMixpanelIdentity = () => mixpanel.reset();
