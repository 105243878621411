import './style.scss';

import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import { sentryException } from 'services/SentryLogging';

import content from '../../../content.json';
import { Stylist } from '../../../services';
import { Rating } from '../../';

const { modal } = content;
const AddReview = ({ stylist, changeModal, loadStylistReviews, user }) => {
    const [rating, setRating] = useState({});
    const [picture, setPicture] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);

    const onPictureChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = function () {
            setPicture(reader.result.substr(reader.result.indexOf(',') + 1));
        };
        reader.readAsDataURL(file);
    };

    const onSubmit = async () => {
        setLoading(true);
        try {
            const {
                data: { message }
            } = await Stylist.review(stylist.uuid, {
                user_uuid: user.user_uuid,
                rate: rating.score,
                description,
                picture
            });
            if (message === 'success') {
                loadStylistReviews(stylist.uuid);
                changeModal('Tip', { stylist });
            }
            setLoading(false);
        } catch (e) {
            sentryException(e, "Couldn't submit a review");
            setLoading(false);
        }
    };

    return (
        <span className="add-review">
            {loading && <div className="loader" />}
            <Modal.Header closeButton>
                <Image className="profile-picture" src={stylist.profile_picture} />
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <p>{modal.review.question.replace('%stylist%', stylist.first_name)}</p>
                            <Rating
                                ratings={modal.review.ratings.map((rating) => ({
                                    ...rating,
                                    label: rating.label.replace('%stylist%', stylist.first_name)
                                }))}
                                onSelect={setRating}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control
                                as="textarea"
                                placeholder={modal.review.placeholder}
                                onChange={(e) => setDescription(e.target.value)}
                                disabled={!(rating.score >= 0)}
                            />
                        </Col>
                    </Row>
                    <Row className="upload-section">
                        {rating.score >= 0 && !picture.length && (
                            <Col className="upload-btn">
                                <label htmlFor="review-image">
                                    <FontAwesomeIcon icon={faCamera} className="d-none d-sm-flex" />
                                    {modal.review.upload}
                                </label>
                                <input
                                    type="file"
                                    id="review-image"
                                    accept="image/jpeg"
                                    onChange={onPictureChange}
                                />
                            </Col>
                        )}
                        {picture.length > 0 && (
                            <Col key={picture} className="upload-image">
                                <div className="remove" onClick={() => setPicture('')}>
                                    <span className="close" />
                                </div>
                                <Image src={`data:image/jpeg;base64,${picture}`} fluid />
                            </Col>
                        )}
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" disabled={!(rating.score >= 0)} onClick={onSubmit}>
                    {modal.review.submit}
                </Button>
                <p>{modal.review.public}</p>
            </Modal.Footer>
        </span>
    );
};

export default AddReview;
