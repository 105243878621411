import { sentryException } from 'services/SentryLogging';
import { getUserId } from 'services/utils/user-utils';

import campaigns from '../../../campaigns';
import { Config, Payments, Tracking, User } from '../../../services';
import { removeFromCart } from '../users/actions';

export const SET_PRICE = 'SET_PRICE';
export const TOGGLE_MOBILE_SIDE_COLLAPSE = 'TOGGLE_MOBILE_SIDE_COLLAPSE';
export const UPDATE_COUPON_START = 'UPDATE_COUPON_START';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAILED = 'UPDATE_COUPON_FAILED';
export const CLEAR_COUPON = 'CLEAR_COUPON';

export const PAYMENT_START = 'PAYMENT_START';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';
export const CLEAR_RESULT = 'CLEAR_RESULT';

export const USER_CARDS_START = 'USER_CARDS_START';
export const USER_CARDS_SUCCESS = 'USER_CARDS_SUCCESS';
export const UPDATE_PAYMENT_TOKEN = 'UPDATE_PAYMENT_TOKEN';
export const PAYMENT_BUTTON_DISABLED = 'PAYMENT_BUTTON_DISABLED';

export const UPDATE_GIFTCARD = 'UPDATE_GIFTCARD';
export const CLEAR_GIFTCARD = 'CLEAR_GIFTCARD';
export const UPDATE_GIFTCARD_STATE = 'UPDATE_GIFTCARD_STATE';
export const GIFT_CARD_ERROR = 'GIFT_CARD_ERROR';

export const LOAD_COUNTRIES_SUCCESS = 'LOAD_COUNTRIES_SUCCESS';
export const LOAD_COUNTRIES_FAILED = 'LOAD_COUNTRIES_FAILED';
export const LOAD_STATES_SUCCESS = 'LOAD_STATES_SUCCESS';
export const LOAD_STATES_FAILED = 'LOAD_STATES_FAILED';
export const SET_PLAN_MODEL = 'SET_PLAN_MODEL';
export const UPDATE_PAYMENT_HISOTRY = 'UPDATE_PAYMENT_HISOTRY';

export const setPrice = (price) => ({
    type: SET_PRICE,
    payload: { price }
});

export const setPlanModel = (model) => ({
    type: SET_PLAN_MODEL,
    payload: model
});

export const updateCouponStart = () => ({
    type: UPDATE_COUPON_START
});

export const updateCouponSuccess = (coupon) => ({
    type: UPDATE_COUPON_SUCCESS,
    payload: { coupon }
});

export const clearCoupon = () => ({
    type: CLEAR_COUPON
});

export const updateCouponFailed = (error) => ({
    type: UPDATE_COUPON_FAILED,
    payload: { error }
});

export const paymentStart = () => ({
    type: PAYMENT_START
});

export const paymentSuccess = (result) => ({
    type: PAYMENT_SUCCESS,
    payload: { result }
});

export const paymentFailed = (error) => ({
    type: PAYMENT_FAILED,
    payload: { error }
});

export const clearResult = () => ({
    type: CLEAR_RESULT
});

export const userCardsSuccess = (cards, token) => ({
    type: USER_CARDS_SUCCESS,
    payload: { cards, token }
});

export const toggleMobileCollapse = (content) => ({
    type: TOGGLE_MOBILE_SIDE_COLLAPSE,
    payload: { content }
});

export const updatePaymentToken = (token) => ({
    type: UPDATE_PAYMENT_TOKEN,
    payload: { token }
});

export const paymentButtonDisabled = (buttonDisabled) => ({
    type: PAYMENT_BUTTON_DISABLED,
    payload: { buttonDisabled }
});

export const updateGiftCard = (giftcard) => ({
    type: UPDATE_GIFTCARD,
    payload: { giftcard }
});

export const clearGiftCard = () => ({
    type: CLEAR_GIFTCARD
});

export const updateGiftCardState = (giftcardState) => ({
    type: UPDATE_GIFTCARD_STATE,
    payload: { giftcardState }
});

export const giftCardError = (error) => ({
    type: GIFT_CARD_ERROR,
    payload: { error }
});

export const loadCountriesSuccess = (countries) => ({
    type: LOAD_COUNTRIES_SUCCESS,
    payload: { countries }
});

export const loadCountriesFailed = (error) => ({
    type: LOAD_COUNTRIES_FAILED,
    payload: { error }
});

export const loadStatesSuccess = (states) => ({
    type: LOAD_STATES_SUCCESS,
    payload: { states }
});

export const loadStatesFailed = (error) => ({
    type: LOAD_STATES_FAILED,
    payload: { error }
});

export const updatePaymentHistory = (paymentHistory) => ({
    type: UPDATE_PAYMENT_HISOTRY,
    payload: paymentHistory
});

export const submitCoupon = (code) => {
    return async (dispatch, getState) => {
        dispatch(updateCouponStart());
        try {
            const { plan } = getState().booking;
            let coupon = {};
            if (code) {
                coupon = await Payments.getCoupon(code);
                if (!coupon.data.valid) {
                    dispatch(updateCouponFailed('invalid Code'));
                    return false;
                }
            }
            const invoice = await Payments.getInvoice(plan.value, code);
            dispatch(updateCouponSuccess({ ...coupon.data, ...invoice.data }));
        } catch (e) {
            dispatch(updateCouponFailed(e));
        }
    };
};

export const pay = () => {
    return async (dispatch, getState) => {
        dispatch(paymentStart());
        try {
            const uuid = getUserId();
            const { stylist, plan, goal, campaign } = getState().booking;
            let { model, price, coupon, paymentToken } = getState().payments;

            let data = {
                subtotal: price,
                customer_uuid: uuid,
                payment_token: paymentToken,
                coupon_code: coupon ? coupon.code : '',
                plan: plan.value,
                stylist_uuid: stylist.uuid,
                platform: Config.isMobile() ? 'mobile-web' : 'desktop-web'
            };
            if (goal.value === 'closet_cleanout') data.goal = 'GOAL_CLOSET_CLEANOUT';
            if (goal.value === 'close' || (campaign && campaigns[campaign].checkout.onetime))
                model = 'onetime';

            const result = await Payments.payInvoice(data, model);
            const paymentData = result.data.data;

            Tracking.google({
                type: 'event',
                event: 'purchase',
                data: {
                    transaction_id: paymentData.id,
                    value: paymentData.total,
                    currency: 'USD'
                }
            });
            Tracking.facebook('track', 'Purchase', {
                device: Config.isMobile() ? 'mobile' : 'desktop',
                content_category: paymentData.plan_name,
                content_type: 'product',
                content_ids: [paymentData.stylist_uuid],
                currency: 'USD',
                num_items: 1,
                value: paymentData.total
            });
            Tracking.tag({
                event: 'eec.purchase',
                ecommerce: {
                    currencyCode: 'USD',
                    purchase: {
                        actionField: {
                            id: paymentData.id,
                            revenue: paymentData.total,
                            coupon: coupon ? coupon.code : ''
                        },
                        products: [
                            {
                                id: stylist.uuid,
                                name: stylist.name,
                                category: '/category/stylist/',
                                variant: goal.value,
                                price: paymentData.total,
                                quantity: 1,
                                dimension3: 'Ecommerce'
                            }
                        ]
                    }
                }
            });
            try {
                await Tracking.report(
                    {
                        event_type: 'clicked_pay_CTA',
                        uuid,
                        stylist_uuid: stylist.uuid
                    },
                    token
                );
            } catch (e) {
                sentryException(e, 'Cannot track CTA pay click');
            }
            dispatch(paymentSuccess(paymentData));
        } catch (e) {
            sentryException(e, "Couldn't process payment request");
            dispatch(paymentFailed(e));
        }
    };
};

export const payGiftCard = (params) => {
    return async (dispatch, getState) => {
        dispatch(paymentStart());
        try {
            const uuid = getUserId();
            const { stylist } = getState().stylists;
            let { giftcard, paymentToken } = getState().payments;

            const data = {
                ...params,
                payment_token: paymentToken,
                customer_uuid: uuid,
                recipient_name: giftcard.recipientName,
                recipient_email: giftcard.recipientEmail,
                amount: giftcard.amount,
                additional_amount: giftcard.additionalAmount || 0,
                message: giftcard.message,
                sender_first_name: giftcard.senderName.split(' ')[0],
                sender_last_name: giftcard.senderName.split(' ')[1],
                sender_email: giftcard.senderEmail,
                picture: giftcard.design
            };

            if (stylist) {
                data.stylist_uuid = stylist.uuid;
            }
            const result = await Payments.payGiftCard(data);
            const paymentData = result.data.data;

            Tracking.google({
                type: 'event',
                event: 'purchase',
                data: {
                    transaction_id: paymentData.id,
                    value: paymentData.amount,
                    currency: 'USD',
                    event_category: 'gift-card',
                    num_items: 1
                }
            });
            Tracking.facebook('track', 'Purchase', {
                device: Config.isMobile() ? 'mobile' : 'desktop',
                value: paymentData.amount,
                currency: 'USD',
                content_name: 'gift-card',
                num_items: 1
            });
            Tracking.tag({
                event: 'eec.purchase',
                ecommerce: {
                    currencyCode: 'USD',
                    purchase: {
                        actionField: {
                            id: paymentData.uuid,
                            revenue: paymentData.amount
                        },
                        products: [
                            {
                                name: 'Gift Card',
                                category: '/category/gift-card/',
                                variant: `Gift Card - ${giftcard.amount}`,
                                quantity: 1,
                                dimension3: 'Ecommerce'
                            }
                        ]
                    }
                }
            });

            dispatch(paymentSuccess(paymentData));
        } catch (e) {
            dispatch(paymentFailed(e));
        }
    };
};

export const getUserCards = () => {
    return async (dispatch) => {
        try {
            const { data } = await Payments.getCards();
            const normalizedCards =
                data?.cards?.map((card) => ({
                    stripe_id: card.id,
                    brand: card.brand,
                    exp_month: card.expiry.month,
                    exp_year: card.expiry.year,
                    last4: card.last4,
                    default: card.default
                })) || [];

            dispatch(userCardsSuccess([...normalizedCards]));
        } catch (e) {
            dispatch(userCardsSuccess([]));
        }
    };
};

export const updateUserCards = (data, token) => {
    return (dispatch) => {
        dispatch(userCardsSuccess([data], token));
    };
};

export const payTip = (amount, data) => {
    return async (dispatch, getState) => {
        dispatch(paymentStart());
        try {
            const { stylist } = getState().stylists;

            const params = {
                channel_sid: data.channel_sid || stylist.channel_sid,
                stylist_uuid: data ? data.stylist.uuid : stylist.uuid,
                amount
            };

            const result = await Payments.payTip(params);
            const paymentData = result.data.data;

            dispatch(paymentSuccess(paymentData));
        } catch (e) {
            dispatch(paymentFailed(e));
        }
    };
};

export const checkoutList = (data, billing) => {
    return async (dispatch, getState) => {
        dispatch(paymentStart());
        try {
            const { paymentToken } = getState().payments;
            const uuid = getUserId();

            await User.updateBillingDetails({
                user_uuid: uuid,
                data: { billing_details: billing }
            });
            const result = await Payments.checkoutList({ ...data, user_uuid: uuid });
            const paymentData = result.data.data;

            dispatch(paymentSuccess({ ...paymentData, paymentToken }));
        } catch (e) {
            dispatch(paymentFailed(e));
        }
    };
};

export const checkoutPay = (data) => {
    return async (dispatch, getState) => {
        dispatch(paymentStart());
        try {
            const { cart } = getState().users;
            const { paymentToken } = getState().payments;
            const uuid = getUserId();

            const result = await Payments.checkoutListPay({
                ...data,
                user_uuid: uuid,
                payment_token: paymentToken,
                client: Config.isMobile() ? 'web-mobile' : 'web-desktop'
            });

            if (cart) {
                data.items.forEach((item) => {
                    dispatch(removeFromCart(item));
                });
            }

            // add facebook pixel
            Tracking.facebook('track', 'Purchase', {
                device: Config.isMobile() ? 'mobile' : 'desktop',
                value: result.data.data.total,
                currency: 'USD',
                content_name: 'item',
                num_items: 1
            });

            dispatch(paymentSuccess({ ...result.data.data, payment: true }));
        } catch (e) {
            dispatch(paymentFailed(e));
        }
    };
};

export const getCountries = () => {
    return async (dispatch) => {
        try {
            const { data } = await Payments.getCountries();
            dispatch(loadCountriesSuccess(data));
        } catch (error) {
            dispatch(loadCountriesFailed(error));
        }
    };
};

export const getStates = (country) => {
    return async (dispatch) => {
        try {
            const { data } = await Payments.getStates(country);
            dispatch(loadStatesSuccess(data));
        } catch (error) {
            dispatch(loadStatesFailed(error));
        }
    };
};

export const updatePaymentMethod = (stripeToken) => {
    return async (dispatch) => {
        try {
            await Payments.updatePaymentMethod({
                data: { cardToken: stripeToken, setDefault: true }
            });
            dispatch(getUserCards());
        } catch (error) {
            dispatch(paymentFailed(error));
        }
    };
};

export const getPaymentHistory = () => {
    return async (dispatch) => {
        try {
            const userId = getUserId();
            const data = await Payments.sessions(userId);
            dispatch(updatePaymentHistory(data));
        } catch (error) {
            sentryException(error, 'Cannot retrieve payment history');
        }
    };
};
