import './style.scss';

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';

import content from '../../../content.json';
import { Loader, ProfilePhoto } from '../..';
import Custom from './Custom';
import Select from './Select';

const { modal } = content;

const Tip = ({
    data,
    stylist,
    pay,
    result,
    error,
    toggleModal,
    clearResult,
    loading,
    getPaymentHistory
}) => {
    const [state, setState] = useState('select');
    const [tip, setTip] = useState('0');
    const PANELS = {
        select: Select,
        custom: Custom
    };

    useEffect(() => {
        if (result) closeModal();
    }, [result]);

    const onSubmit = async () => {
        switch (state) {
            case 'custom':
                setState('select');
                break;
            case 'select':
                const amount = parseInt(tip);
                if (amount) {
                    const tipData = {
                        stylist: data ? data.stylist : null,
                        channel_sid: data ? data.channel_sid : null
                    };
                    await pay(amount, tipData);
                    getPaymentHistory();
                } else closeModal();
                break;
            default:
                setState('select');
        }
    };

    const closeModal = () => {
        clearResult();
        toggleModal();
    };

    const Component = PANELS[state];

    return (
        <span className="tip">
            {loading && <Loader />}
            <Modal.Header closeButton>
                <ProfilePhoto user={data ? data.stylist : stylist} />
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <p className="title">{modal.tip.title}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="text">{modal.tip.text}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Component
                                onChange={state === 'payment' ? onSubmit : setTip}
                                tip={tip}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {state === 'select' && (
                            <Col className="custom-btn" onClick={() => setState('custom')}>
                                {modal.tip.custom}
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col
                            className="error"
                            dangerouslySetInnerHTML={{
                                __html: error && error.payment ? modal.tip.error : ''
                            }}
                        />
                    </Row>
                </Container>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="warning" onClick={onSubmit}>
                    {state === 'custom' ? modal.tip.ok : modal.tip.done}
                </Button>
            </Modal.Footer>
        </span>
    );
};

export default Tip;
