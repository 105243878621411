import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { updateItem } from '../../redux/reducers/style/actions';
import { addToCart, removeFromCart } from '../../redux/reducers/users/actions';
import Chat from './Chat';

const mapStateToProps = ({
    users: { cart },
    services: { isTwilioInitialized },
}) => ({
    isTwilioInitialized,
    cart
});

const mapDispatchToProps = (dispatch) => ({
    updateItem: (item) => dispatch(updateItem(item)),
    addToCart: (item, source) => dispatch(addToCart(item, source)),
    removeFromCart: (item) => dispatch(removeFromCart(item))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat));
