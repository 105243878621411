import Campaign from './Campaign';
import HowItWorks from './HowItWorks';
import Inbox from './Inbox/inbox-component';
import { Lux } from './lux';
import Pricing from './Pricing';
import StylistListPage from './stylistListPage';
import StylistMatch from './stylistMatch';

export default {
    StylistMatch,
    Pricing,
    Inbox,
    Campaign,
    HowItWorks,
    StylistListPage,
    Lux
};
