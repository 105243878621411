import './style.scss';

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import { Page } from '../../components';
import content from '../../content.json';

const texts = content.item;

const ItemConfirmation = ({
    item,
    paymentResult = {},
    billingDetails,
    updateItem,
    clearResult,
    addToCloset,
    history
}) => {
    let { outfit_uuid, item_uuid } = useParams();
    const [closet, setCloset] = useState(true);
    const [context, setContext] = useState();

    useEffect(() => {
        if (history.location) {
            const path = history.location.pathname.split('/')[1];
            setContext(path === 'item' ? '' : path);
        }
        return () => {
            updateItem();
            clearResult();
        };
    }, []);

    useEffect(() => {
        if ((!paymentResult || !paymentResult.payment) && context) {
            history.push(
                `/${context}${outfit_uuid ? `/outfit/${outfit_uuid}` : ''}/item/${item_uuid}`
            );
        }
    }, [paymentResult, context]);

    const closetClick = () => {
        addToCloset(item);
        setCloset(false);
    };

    return (
        <Page footer={false}>
            <Container className="item-confirmation">
                <Row>
                    <Col>
                        <div className="confirmation-details-container">
                            <h1>{texts.confirmation.title}</h1>
                            {paymentResult?.order_number && (
                                <h4>
                                    {texts.confirmation.text.replace(
                                        '%confirmation%',
                                        paymentResult.order_number
                                    )}
                                </h4>
                            )}
                            {billingDetails.address && (
                                <div className="billing-details">
                                    <p className="title">{texts.confirmation.shipping}</p>
                                    <p>{billingDetails.name}</p>
                                    <p>
                                        {billingDetails.address.line1}{' '}
                                        {billingDetails.address.line2}
                                    </p>
                                    <p>{`${billingDetails.address.city}, ${billingDetails.address.state} ${billingDetails.address.postal_code}`}</p>
                                </div>
                            )}
                            <div
                                className={`closet ${!closet ? 'disabled' : ''}`}
                                onClick={closetClick}>
                                {closet ? texts.confirmation.closet : texts.confirmation.added}
                            </div>

                            <Button
                                as={Link}
                                variant="warning"
                                className="continue"
                                to={`/${context}`}>
                                {texts.confirmation.button}
                            </Button>
                        </div>
                    </Col>
                    <Col className="d-none d-sm-block">
                        <div className="item-image-container">
                            <Image key={item.picture} src={item.picture} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};

export default ItemConfirmation;
