import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Config from 'services/Config';
import { getUserToken } from 'services/utils/user-utils';

const baseUrl = Config.get('api-gw');

/**
 * initialize an empty root api service that we'll
 * inject endpoints into later as needed
 */
export const rootSplitApi = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: baseUrl,
		prepareHeaders: (headers) => {
			const token = getUserToken();

			if (token) {
				headers.set('token', token);
			}
			return headers;
		}
	}),

	endpoints: () => ({})
});
