import '../Signup/style.scss';

import content from 'content.json';
import { useWithDispatch } from 'hooks';
import React, { FormEvent, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Validator } from 'services';
import { resetError } from 'store/error/actions';
import { useErrorStore } from 'store/error/reducer';
import { changeModal, toggleModal } from 'store/ui/actions';
import { useUIStore } from 'store/ui/reducer';
import { login } from 'store/user/actions';
import { useUserStore } from 'store/user/reducer';

import { ICredentials } from '../../../services/auth-service';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from '../../../services/mixpanel';
import { GoogleLogin } from '../../GoogleLogin/GoogleLogin';
import Loader from '../../Loader';

const { modal } = content;

const getDefaultRedirectUrl = (pathname: string) => {
    switch (pathname) {
        case '/feed':
            return '/feed';
        default:
            return '/inbox';
    }
};

const Signin: React.FC = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [remember, setRemember] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<{ email?: string; password?: string } | null>(null);
    const texts = modal.signin;
    const user = useUserStore((store) => store.user);
    const redirectUrl = useUIStore((store) => store.redirectUrl);
    const errorsStore = useErrorStore((store) => store.errors);

    const loginAction = useWithDispatch(login);
    const changeModalAction = useWithDispatch(changeModal);
    const toggleModalAction = useWithDispatch(toggleModal);
    const resetErrorAction = useWithDispatch(resetError);

    const validate = () => {
        const errors = {
            ...Validator.email(email),
            ...Validator.password(password)
        };
        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    };

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!loading && validate()) {
            setLoading(true);
            const result = await loginAction(
                {
                    email,
                    password,
                    remember
                },
                'form'
            );
            !result && setLoading(false);
        }
    };

    const onGoogleLoginSuccess = (credentials: Partial<ICredentials>) => {
        setLoading(true);
        loginAction(credentials, 'google');
    };

    const onSignupClick = () => {
        trackEvent({
            name: MP_EVENTS.SIGNUP_CLICK,
            properties: {
                [MP_PROPS.SOURCE]: MP_VALUES.NO_ACCOUNT_LINK
            }
        });

        changeModalAction({ type: 'Signup', data: { source: MP_VALUES.NO_ACCOUNT_LINK } });
    };

    useEffect(() => {
        resetErrorAction();

        if (user && user.user_uuid) {
            setLoading(false);
            toggleModalAction({ type: null });
            history.push(
                redirectUrl ? redirectUrl : getDefaultRedirectUrl(history.location.pathname)
            );
        }
    }, [user, redirectUrl, history, toggleModalAction, resetErrorAction]);

    const loginMarkerClass = 'login-modal-button';

    return (
        <div className="signup-modal">
            <div className="logo" />
            <Modal.Header closeButton>
                <div className="title">Log in</div>
            </Modal.Header>
            <GoogleLogin
                disabled={loading}
                onSuccess={onGoogleLoginSuccess}
                markerClass={loginMarkerClass}
            />

            <form onSubmit={onSubmit}>
                <div className="input-container">
                    <input
                        data-test-id="login-email"
                        name="name"
                        type="email"
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(e.target.value);
                        }}
                        placeholder={texts.email}
                        required
                    />
                    {errors?.email && <div className="validation-error">{errors.email}</div>}
                    {errorsStore[0]?.field === 'email' && (
                        <div className="validation-error">{errorsStore[0]?.message}</div>
                    )}
                </div>

                <div className="input-container">
                    <input
                        data-test-id="login-password"
                        name="password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={texts.password}
                        required
                    />
                    {errors?.password && <div className="validation-error">{errors.password}</div>}
                    {errorsStore && errorsStore[0]?.field === 'password' && (
                        <div className="validation-error">{errorsStore[0]?.message}</div>
                    )}
                </div>

                <div className="input-container actions">
                    <div className="remember-me">
                        <input
                            type="checkbox"
                            name="remember"
                            onChange={(e) => setRemember(e.target.checked)}
                        />
                        <label htmlFor="remember">{texts.remember}</label>
                    </div>

                    <strong onClick={() => changeModalAction({ type: 'ResetPassword', data: {} })}>
                        {texts.forgot}
                    </strong>
                </div>

                <button
                    id="submit"
                    type="submit"
                    className={`submit-button ${loginMarkerClass}`}
                    disabled={loading}
                    data-test-id="login-button">
                    {texts.signin}
                </button>
            </form>

            <div className="no-account-message">
                {texts.account}&nbsp;
                <strong onClick={onSignupClick}>{texts.join}</strong>
            </div>
            {loading && <Loader />}
        </div>
    );
};

export default Signin;
