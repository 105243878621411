import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setModel } from 'store/payments/actions';

import {
    clearCoupon,
    clearResult,
    getUserCards,
    setPlanModel,
    setPrice,
    submitCoupon
} from '../../redux/reducers/payments/actions';
import Payment from './Payment';
import { setMixpanelStore } from 'store/common/actions';

const mapStateToProps = ({
    user: { user },
    booking: { plan, stylist, goal, campaign },
    payments: { model, loading, result },
    common: { mixpanelStore }
}) => ({
    plan,
    goal,
    model,
    campaign,
    loading,
    result,
    stylist,
    user,
    mixpanelStore
});

const mapDispatchToProps = (dispatch) => ({
    setPrice: (price) => dispatch(setPrice(price)),
    setPlanModel: (model) => dispatch(setPlanModel(model)),
    setModel: (model) => setModel(dispatch, model),
    submitCoupon: (code) => dispatch(submitCoupon(code)),
    clearCoupon: () => dispatch(clearCoupon()),
    clearResult: () => dispatch(clearResult()),
    getUserCards: () => dispatch(getUserCards()),
    setMixpanelStore: (data) => setMixpanelStore(dispatch, data)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payment));
