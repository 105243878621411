import './style.scss';

import React, { createElement, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import { Page } from 'components';
import Header from './Header';
import Sections from './Sections';
import { useIsMobile, useWithDispatch } from 'hooks';
import { setGridSize } from 'store/profile/actions';
import { AvailableSections, baseURL } from './consts';
import { getUserToken } from 'services/utils/user-utils';

export default () => {
    const history = useHistory();
    const { tab = AvailableSections.Closet } = useParams<{ tab: AvailableSections }>();
    const [section, setSection] = useState<any>();
    const isMobile = useIsMobile();
    const setGridSizeAction = useWithDispatch(setGridSize);

    useEffect(() => {
        const token = getUserToken();
        if (!token) history.push('/');

        if (isMobile) setGridSizeAction('small');
    }, []);

    useEffect(() => {
        if (Sections[tab]) {
            setSection(Sections[tab]);
        } else {
            history.push(`${baseURL}/${AvailableSections.Closet}`);
        }
    }, [tab]);

    const onMenuSelect = (selection: string) => history.push(`${baseURL}/${selection}`);

    return (
        <Page footer={false}>
            <Container className="profile" fluid>
                <Header onMenuSelect={onMenuSelect} active={tab} />

                <Row className="content">
                    <Col> {section ? createElement(section) : ''}</Col>
                </Row>
            </Container>
        </Page>
    );
};
