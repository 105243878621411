import { errorReducer, name as errorName } from './error/reducer';
import { commonReducer, name as commonName } from './common/reducer';
import { userReducer, name as userName } from './user/reducer';
import { paymentReducer, name as paymentName } from './payments/reducer';
import { uiReducer, name as uiName } from './ui/reducer';
import { combineReducers } from 'redux';
import { bookingReducer, name as bookingName } from './booking/reducer';
import { servicesReducer, name as servicesName } from './services/reducer';
import { profileReducer, name as profileName } from 'store/profile/reducer';

import usersReducer from 'redux/reducers/users';
import onboardingReducer from 'redux/reducers/onboarding';
import styleReducer from 'redux/reducers/style';
import { stylistsReducer, name as stylistsName } from 'redux/reducers/stylists';
import paymentsReducer from 'redux/reducers/payments';
import { rootSplitApi } from './root-api-slice';

export const rootReducer = combineReducers({
    [commonName]: commonReducer,
    [userName]: userReducer,
    [bookingName]: bookingReducer,
    [errorName]: errorReducer,
    [paymentName]: paymentReducer,
    [uiName]: uiReducer,
    [servicesName]: servicesReducer,
    [profileName]: profileReducer,
    users: usersReducer,
    onboarding: onboardingReducer,
    style: styleReducer,
    [stylistsName]: stylistsReducer,
    payments: paymentsReducer,
    [rootSplitApi.reducerPath]: rootSplitApi.reducer
});
