import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { useUserStore } from '../../../store/user/reducer';

export const GetStartedButton: FC = () => {
    const user = useUserStore((store) => store?.user);
    const history = useHistory();

    const onClick = () => {
        history.push(user ? '/stylistSearch' : '/onboarding');
    };
    return (
        <button className="btn btn-dark" onClick={onClick}>
            Get Started
        </button>
    );
};
