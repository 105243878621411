import moment from 'moment';
import React, { useEffect } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import { Pagination } from '../../../components';
import Content from '../../../content';

const Review = ({ review }) => (
    <Container className="review">
        <Row>
            <Col className="reviewer">
                <div className="profile-image">
                    {review.user.picture_medium ? (
                        <Image src={review.user.picture_medium} />
                    ) : (
                        <div className="default-image">
                            {review.user.first_name && review.user.first_name[0]}
                            {review.user.lastName && review.user.lastName[0]}
                        </div>
                    )}
                </div>
                <div className="info">
                    <div className="name">{review.user.first_name}</div>
                    <div className="date">{moment(review.created).format('MMMM YYYY')}</div>
                </div>
            </Col>
        </Row>
        <Row>
            <Col className="description">
                {review.description}
                {review.picture && <Image src={review.picture} fluid />}
            </Col>
        </Row>
    </Container>
);

const Reviews = ({ reviews = [], toggleModal, loadStylistReviews, newStylist }) => {
    const stylist = newStylist;
    const stylistId = stylist.uuid;
    const REVIEWS_PER_PAGE = 5;
    const reviewsRef = React.createRef();

    useEffect(() => {
        if (stylistId) {
            loadStylistReviews(stylistId);
        }
    }, [stylistId]);

    const onPageChange = (page) => {
        window.scrollTo({
            top: reviewsRef.current.offsetParent.offsetTop,
            behavior: 'smooth'
        });
        loadStylistReviews(stylistId, page * REVIEWS_PER_PAGE);
    };

    return (
        <Row className="stylist-reviews">
            {stylist && stylist.has_session && (
                <Col>
                    <Button
                        className="add-review-btn"
                        variant="outline-dark"
                        onClick={() => toggleModal({ type: 'AddReview' })}
                    >
                        {Content.stylist.reviews.add}
                    </Button>
                </Col>
            )}

            {reviews.length ? (
                <Col>
                    <div ref={reviewsRef}>
                        <div className="title">
                            {Content.stylist.reviews.title.replace(
                                '%number%',
                                stylist.reviews_count
                            )}
                        </div>

                        <Container className="reviews">
                            {reviews.map((review, i) => (
                                <Row key={i} className="review-row">
                                    <Col>
                                        <Review review={review} />
                                    </Col>
                                </Row>
                            ))}
                        </Container>

                        {stylist.reviews_count > 5 && (
                            <Pagination
                                total={stylist.reviews_count}
                                pageSize={REVIEWS_PER_PAGE}
                                onChange={onPageChange}
                                showNumbers={false}
                            />
                        )}
                        <hr />
                    </div>
                </Col>
            ) : (
                ''
            )}
        </Row>
    );
};

export default Reviews;
