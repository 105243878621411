//import './style.scss';

import React from 'react';
import { Carousel, Col, Container, Image, Row } from 'react-bootstrap';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { capitalize } from 'services/utils/string-utils';

import { RadioButton } from '../';
const StyleSelector = ({ styles, question, answers, onSelect, current, selections }) => {
    const isChecked = (style, answer) => selections[style] === answer.value;

    const onSelectClick = (style, answer) => {
        if (!isChecked(style, answer)) {
            trackEvent({
                name: MP_EVENTS.ONBOARDING_STYLE_SELECTION,
                properties: {
                    [MP_PROPS.PAGE_NAME]: style,
                    [MP_PROPS.STYLE_SELECTION]: answer.value
                }
            });
            onSelect({
                name: style,
                rate: answer.value
            });
        }
    };

    return (
        <Carousel
            className="style-selector"
            controls={false}
            indicators={false}
            fade={true}
            interval={null}
            activeIndex={current}
            onSelect={() => {}} // required by Carousel component if using activeIndex
        >
            {styles.map(({ style, displayName, picture }) => {
                return (
                    <Carousel.Item key={style}>
                        <Container className="style" fluid>
                            <Row>
                                <Col>
                                    <p className="question">
                                        {question.replace('%style%', capitalize(displayName))}
                                    </p>
                                    <Image src={picture} />
                                </Col>
                            </Row>
                            <Row className="answers">
                                {answers.map((answer) => {
                                    return (
                                        <Col
                                            className="no-padding"
                                            key={`${style}_${answer.value}`}>
                                            <RadioButton
                                                direction="vertical"
                                                key={`${style}${answer.value}`}
                                                id={`${style}_${answer.value}`}
                                                value={`${style}${answer.value}`}
                                                isChecked={isChecked(style, answer)}
                                                onClick={() => onSelectClick(style, answer)}
                                                label={answer.text}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Container>
                    </Carousel.Item>
                );
            })}
        </Carousel>
    );
};

export default StyleSelector;
