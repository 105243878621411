import './style.scss';

import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';

import content from '../../../content.json';
import { useUserName } from '../../../hooks';
import { Tracking } from '../../../services';

const { giftcards } = content;

const Details = ({ user, giftcard, updateGiftCard, giftCardError, error = {}, onSubmit }) => {
    const { fullname } = useUserName(user);
    const { amount, additionalAmount, recipientName, recipientEmail, senderName, senderEmail } =
        giftcard;
    const [custom, setCustom] = useState(
        additionalAmount && !giftcards.gifts.find((gift) => gift.price === additionalAmount)
    );
    const [customAmount, setCustomAmount] = useState('');
    const customInputRef = useRef(null);

    const onShoppingCreditClick = (price) => {
        setCustom(false);
        updateGiftCard({
            additionalAmount: !custom && price === additionalAmount ? 0 : price
        });
    };

    const onCustomAmountChange = ({ target: { value } }) => {
        const amount = Math.abs(parseInt(value));

        updateGiftCard({
            additionalAmount: amount || 0
        });
    };

    const validateCustom = () => {
        if (additionalAmount < 0) {
            giftCardError({ custom: true });
        }
    };

    useEffect(() => {
        if (user) {
            updateGiftCard({
                senderName: fullname,
                senderEmail: user.email
            });
        }
    }, [user, updateGiftCard, fullname]);

    useEffect(() => {
        if (custom && customInputRef.current) {
            customInputRef.current.focus();
        }
    }, [custom]);

    useEffect(() => setCustomAmount(additionalAmount || ''), [additionalAmount]);

    return (
        <Container className="details" fluid>
            <Row>
                <Col>
                    <p className="title">{giftcards.checkout.choose}</p>
                </Col>
            </Row>
            <Row className={`amounts ${error && error.amount ? 'error' : ''}`}>
                {giftcards.packages.map((pkg) => (
                    <Col key={pkg.price}>
                        <Button
                            variant="light"
                            className={amount === pkg.price ? 'selected' : ''}
                            onClick={() => {
                                Tracking.tag({
                                    event: 'eec.add',
                                    ecommerce: {
                                        add: {
                                            actionField: { list: 'Shopping cart' },
                                            products: [
                                                {
                                                    name: 'Gift Card',
                                                    category: '/category/gift-card/',
                                                    variant: `Gift Card - ${amount}`,
                                                    quantity: 1,
                                                    dimension3: 'Ecommerce'
                                                }
                                            ]
                                        }
                                    }
                                });
                                updateGiftCard({ amount: pkg.price });
                            }}>
                            <span>${pkg.price}</span>
                            <label>{pkg.label}</label>
                        </Button>
                    </Col>
                ))}
            </Row>

            <Row>
                <Col>
                    <p className="title">{giftcards.checkout.chooseGift}</p>
                </Col>
            </Row>
            <Row className={`amounts ${error && error.additionalAmount ? 'error' : ''}`}>
                {giftcards.gifts.map(({ price, label }) => (
                    <Col key={price}>
                        <Button
                            variant="light"
                            className={!custom && price === additionalAmount ? 'selected' : ''}
                            onClick={() => onShoppingCreditClick(price)}>
                            {label}
                        </Button>
                    </Col>
                ))}
                <Col className="custom">
                    {custom ? (
                        <InputGroup className="selected">
                            <Form.Control
                                as="input"
                                type="number"
                                min={0}
                                ref={customInputRef}
                                onChange={onCustomAmountChange}
                                value={customAmount}
                                onBlur={validateCustom}
                            />
                        </InputGroup>
                    ) : (
                        <Button variant="light" onClick={() => setCustom(true)}>
                            {giftcards.checkout.custom}
                        </Button>
                    )}
                </Col>
                {error && error.custom && (
                    <p className="error custom">{giftcards.checkout.customError}</p>
                )}
            </Row>

            <Row>
                <Container className="input-fields" fluid>
                    <Row>
                        <Col>
                            <Form.Label>{giftcards.checkout.to}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type="text"
                                value={recipientName || ''}
                                placeholder={giftcards.checkout.recipientNamePlaceholder}
                                onChange={(e) => updateGiftCard({ recipientName: e.target.value })}
                            />
                            {error && error.recipientName && (
                                <p className="error">
                                    {error.recipientName.name || error.recipientName.lastname}
                                </p>
                            )}
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type="email"
                                value={recipientEmail || ''}
                                placeholder={giftcards.checkout.recipientEmailPlaceholder}
                                onChange={(e) => updateGiftCard({ recipientEmail: e.target.value })}
                            />
                            {error && error.recipientEmail && (
                                <p className="error">{error.recipientEmail.email}</p>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Row>

            <Row>
                <Container className="input-fields" fluid>
                    <Row>
                        <Col>
                            <Form.Label>{giftcards.checkout.from}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type="text"
                                value={senderName || ''}
                                placeholder={giftcards.checkout.senderNamePlaceholder}
                                onChange={(e) => {
                                    updateGiftCard({ senderName: e.target.value });
                                }}
                            />
                            {error && error.senderName && (
                                <p className="error">
                                    {error.senderName.name || error.senderName.lastname}
                                </p>
                            )}
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type="email"
                                value={senderEmail || ''}
                                placeholder={giftcards.checkout.senderEmailPlaceholder}
                                onChange={(e) => updateGiftCard({ senderEmail: e.target.value })}
                            />
                            {error && error.senderEmail && (
                                <p className="error">{error.senderEmail.email}</p>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control
                                as="textarea"
                                placeholder={giftcards.checkout.messagePlaceholder}
                                onChange={(e) => updateGiftCard({ message: e.target.value })}
                            />
                            <p className="comment">{giftcards.checkout.messageComment}</p>
                        </Col>
                    </Row>
                    <Row className="d-none d-sm-flex">
                        <Col>
                            <Button variant="dark" onClick={onSubmit}>
                                {giftcards.checkout.continue}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </Container>
    );
};

export default Details;
