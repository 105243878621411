import './style.scss';

import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { Page, Plans } from '../../components';
import content from '../../content.json';
import GoalsHeader from './GoalsHeader';
import { Goal, GoalPlan, planType } from 'types/plans';
import { StylistUser } from 'types/stylist';
import { Client } from 'types/user';
import { useWithDispatch } from 'hooks';
import { trackEvent, MP_EVENTS, MP_PROPS } from 'services/mixpanel';
import { useCommonStore } from 'store/common/reducer';
import { setMixpanelStore } from 'store/common/actions';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';

const { goals, pricing } = content;

interface IGoalsPlans {
    goal: Goal;
    selectPlan: (plan: GoalPlan) => void;
    clearPlan: () => void;
    stylist: StylistUser;
    user: Client;
}

const GoalsPlans: React.FC<IGoalsPlans> = ({ goal, selectPlan, clearPlan, stylist, user }) => {
    const history = useHistory();
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const bookingSource = useCommonStore((store) => store.bookingSource);
    const setMixpanelStoreAction = useWithDispatch(setMixpanelStore);

    useEffect(() => {
        if (stylist && user)
            trackEvent({
                name: MP_EVENTS.PLAN_VIEWS,
                properties: {
                    ...mixpanelStore.onboarding,
                    ...bookingSourceToProps(bookingSource)
                }
            });
            klaviyoTrack(KlaviyoEvent.GOAL_VIEW);
    }, []);

    const onSelect = (plan: GoalPlan) => {
        setMixpanelStoreAction({
            onboarding: {
                ...mixpanelStore.onboarding,
                [MP_PROPS.PLAN_TYPE]: plan.value
            }
        });
        trackEvent({
            name: MP_EVENTS.PLAN_SELECTION,
            properties: {
                ...mixpanelStore.onboarding,
                [MP_PROPS.PLAN_TYPE]: plan.value,
                ...bookingSourceToProps(bookingSource)
            }
        });
        klaviyoTrack(KlaviyoEvent.GOAL_CLICK, {goal: plan.value})
        selectPlan(plan);
        history.push('/checkout');
    };

    const onBack = () => {
        clearPlan();
        history.goBack();
    };

    return (
        <Page footer={false} header={false} fixedHeader={false}>
            <Container className="goals-plans">
                <GoalsHeader onBack={onBack} stylist={stylist} />
                {goal && (
                    <>
                        <Row className="chat-row">
                            <Col>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: goals.recommendation[
                                            goal.value as planType
                                        ].replace('%plan%', goal.name)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Plans
                                plans={
                                    goal.value === 'closet_cleanout'
                                        ? [pricing.plans[1]]
                                        : goal.value === 'lux'
                                        ? pricing.plans.slice(1)
                                        : pricing.plans
                                }
                                recommended={
                                    goal.value === 'closet_cleanout'
                                        ? pricing.plans[1].value
                                        : goal.value === 'lux'
                                        ? pricing.plans[2].value
                                        : goal.value
                                }
                                onSelect={onSelect}
                            />
                        </Row>
                    </>
                )}
            </Container>
        </Page>
    );
};

export default GoalsPlans;
