import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bookStylist } from 'store/booking/actions';
import { loadUserMatches } from 'store/common/actions';
import { toggleModal } from 'store/ui/actions';

import { loadStylistReviews } from '../../../redux/reducers/stylists/actions';
import { updateFavoriteStylist } from '../../../redux/reducers/users/actions';
import BookBar from './BookBar';
import Experience from './Experience';
import Expertise from './Expertise';
import Matches from './Matches';
import Recommendation from './Recommendation';
import Reviews from './Reviews';
import StylingExperience from './StylingExperience';
import StylistHeader from './StylistHeader';
import Work from './Work';

const mapStateToProps = ({
    users: { favoriteStylists },
    stylists: { stylist, reviews, loading },
    style: { matches },
    common: { stylistsMatchs, mixpanelStore }
}) => ({
    reviews,
    loading,
    matches,
    favoriteStylists,
    stylistsMatchs,
    mixpanelStore
});

const mapDispatchToProps = (dispatch) => ({
    toggleModal: (data) => toggleModal(dispatch, data),
    loadStylistReviews: (uuid, from, count) => dispatch(loadStylistReviews(uuid, from, count)),
    loadUserMatches: (user) => loadUserMatches(dispatch, user),
    bookStylist: (stylist) => bookStylist(dispatch, stylist),
    updateFavoriteStylist: (stylist) => dispatch(updateFavoriteStylist(stylist))
});

export default {
    Recommendation,
    Reviews: withRouter(connect(mapStateToProps, mapDispatchToProps)(Reviews)),
    Experience,
    Expertise,
    StylistHeader,
    BookBar: withRouter(connect(mapStateToProps, mapDispatchToProps)(BookBar)),
    Work,
    StylingExperience: connect(mapStateToProps, mapDispatchToProps)(StylingExperience),
    Matches: withRouter(connect(mapStateToProps, mapDispatchToProps)(Matches))
};
