import './style.scss';

import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

import content from '../../../content.json';

const { giftcards } = content;

const Header = ({ updateGiftCard, history }) => (
    <div className="header">
        <Row className="banner">
            <Col className="text">
                <h1 className="uppercase-header">{giftcards.header.text}</h1>
                <h4>{giftcards.header.subtext}</h4>
            </Col>
            <Col className="image">
                <ImgWithFallback
                    src={giftcards.header.cards.src}
                    fallbackSrc={giftcards.header.cards.fallback}
                    className="cards"
                />
            </Col>
        </Row>
        <Row className="packages">
            {giftcards.packages.map((pkg) => (
                <Col key={pkg.title}>
                    <Card>
                        <Card.Body>
                            <Card.Title>{pkg.title}</Card.Title>
                            <Card.Text>{pkg.content}</Card.Text>
                            <Button
                                variant="light"
                                onClick={() => {
                                    updateGiftCard({ amount: pkg.price });
                                    history.push('/gift-cards/checkout');
                                }}>
                                {`${giftcards.header.button} | ${giftcards.checkout.currency}${pkg.price}`}
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    </div>
);

export default Header;
