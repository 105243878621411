import './style.scss';

import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import content from '../../content.json';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from '../../services/mixpanel';
import { getStylistSearchUrl } from 'services/utils/url-utils';
import { isUserLoggedIn } from 'services/utils/user-utils';

const { landing } = content;

interface IStylingButton {
    user?: any;
    text?: string;
    className?: string;
}
const StylingButton: React.FC<IStylingButton> = ({ user, text, className }) => {
    const onClick = () => {
        trackEvent({
            name: MP_EVENTS.TAKE_ONBOARDING_QUIZ_CLICK,
            properties: {
                [MP_PROPS.SOURCE]: MP_VALUES.LANDING_PAGE
            }
        });
    };

    return (
        <Link
            to={{
                pathname: isUserLoggedIn(user) ? getStylistSearchUrl() : '/onboarding',
                state: { fromHome: true, mpSource: MP_VALUES.LANDING_PAGE }
            }}
            onClick={onClick}
            className={`styling-button ${className}`}>
            <Button variant="dark" className={className}>
                {text ? text : landing.button.text}
            </Button>
        </Link>
    );
};

export default StylingButton;
