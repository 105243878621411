import './style.scss';

import React, { useEffect, useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import Slider from 'react-slick/lib';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';

import { Loader, Page } from '../../components';
import Content from '../../content';
import { useMatchMedia } from '../../hooks/matchMedia';
import Formatter from '../../services/Formatter';
import { FeedItemFragment } from '.';
import FeedMatch from './FeedMatch';

const Feed = ({
    user,
    loading,
    loadFeed,
    updateFeedGender,
    loadUserMatches,
    loadFavoriteLooks,
    feed,
    feedTotal,
    feedGender,
    matches,
    favoriteLooks = [],
    clearOutfit,
    location
}) => {
    const PAGE_ITEMS = 10;
    const [jump, setJump] = useState(false);
    const [loader, setLoader] = useState(false);

    const loadItems = (page = 0) => {
        return loadFeed({
            gender: feedGender,
            from: page,
            count: PAGE_ITEMS
        });
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
        setJump(false);
    };

    useEffect(() => {
        const gender = Formatter.queryString(location.search).gender;
        if (gender) updateFeedGender(gender);

        clearOutfit();
        loadFavoriteLooks();
        loadUserMatches();
    }, []);

    useEffect(() => {
        if (feed.length && location.hash) {
            setLoader(true);
            setTimeout(() => {
                const elm = document.getElementById(location.hash.substr(1));
                if (elm) {
                    elm.scrollIntoView();
                    location.hash = '';
                }
                setLoader(false);
            }, 500);
        }
    }, [feed.length]);

    useEffect(() => {
        if (user && user.gender && !feed.length) updateFeedGender(user.gender);
        loadUserMatches(user);
    }, [user]);

    useEffect(() => {
        if (!feed.length) {
            trackEvent({ name: MP_EVENTS.FEED_PAGE_VIEWS, properties: {} });
            loadItems();
        }
    }, [feedGender]);

    useEffect(() => {
        const onScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > 100 && !jump) {
                setJump(true);
            } else if (scrollTop <= 100 && jump) {
                setJump(false);
            }
        };

        document.addEventListener('scroll', onScroll);

        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, [jump, setJump]);

    return (
        <Page footer={false}>
            {(loading || loader) && <Loader />}
            <div className="feed">
                <div className="outfits">
                    <InfiniteScroll
                        dataLength={feed.length}
                        next={() => loadItems(feed.length)}
                        hasMore={feed.length < feedTotal}>
                        <div className="scrolled-content">
                            <DropdownButton
                                className=""
                                as={ButtonGroup}
                                key="feed-gender"
                                id="gender"
                                variant="secondary"
                                title={Content.feed.gender.toggle.replace('%gender%', feedGender)}>
                                <p>{Content.feed.gender.switch}</p>
                                {Content.feed.gender.options.map(
                                    ({ value, name }) =>
                                        value !== feedGender && (
                                            <Dropdown.Item
                                                eventKey={value}
                                                key={value}
                                                onSelect={(value) => updateFeedGender(value)}>
                                                {name}
                                            </Dropdown.Item>
                                        )
                                )}
                            </DropdownButton>
                            {!loading && (
                                <Matches matches={matches} show={feed.length} user={user} />
                            )}
                            {feed.map(
                                (outfit) =>
                                    outfit?.outfit?.uuid && (
                                        <FeedItemFragment
                                            key={outfit.outfit.uuid}
                                            itm={outfit}
                                            isFavorite={favoriteLooks.find(
                                                (favorite) => favorite.uuid === outfit.outfit.uuid
                                            )}
                                        />
                                    )
                            )}
                        </div>
                    </InfiniteScroll>
                </div>

                <div
                    className={`top-btn d-none d-sm-flex ${jump ? 'show' : 'hide'}`}
                    onClick={scrollToTop}>
                    {Content.feed.top}
                </div>
            </div>
        </Page>
    );
};

const Matches = ({ matches = [], user = undefined, mixpanelStore }) => {
    const hasMatches = user && matches.length > 0;
    const isDesktop = !useMatchMedia('(max-width: 992px)');

    const onTakeQuizClick = () => {
        trackEvent({
            name: MP_EVENTS.TAKE_ONBOARDING_QUIZ_CLICK,
            properties: {
                [MP_PROPS.SOURCE]: MP_VALUES.FEED
            }
        });
    };

    return (
        <div className={`matches ${hasMatches ? 'has-matches' : 'no-matches'}`}>
            <div className="positioner">
                <div className="content">
                    {hasMatches ? (
                        <>
                            <p className="title">{Content.feed.matches.title}</p>
                            <Slider
                                className="feed-matches-carousel"
                                dots={isDesktop}
                                arrows={isDesktop}
                                infinite={false}
                                speed={500}
                                slidesToShow={isDesktop ? 1 : 1.5}
                                slidesToScroll={1}
                                autoplay={false}>
                                {matches.map((match) => (
                                    <FeedMatch
                                        key={match.uuid}
                                        match={match}
                                        additionalData={{
                                            mixpanelStore: mixpanelStore,
                                            matches: matches
                                        }}
                                    />
                                ))}
                            </Slider>
                        </>
                    ) : (
                        <Link
                            className="take-btn"
                            to={{
                                pathname: '/onboarding',
                                state: { mpSource: MP_VALUES.FEED }
                            }}
                            onClick={onTakeQuizClick}
                            target="_self">
                            <span>Let’s Find Your Style Match</span>
                            <u>Take our style quiz</u>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Feed;
