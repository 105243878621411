import {
    CLEAR_LOOKS,
    LOAD_CLIENTS_SUCCESS,
    SET_STYLIST,
    SET_STYLIST_LOOKS,
    SET_STYLIST_REVIEWS,
    STYLIST_ACTION_FAILED,
    STYLIST_LOAD_START,
    STYLIST_LOADING_START,
    UPDATE_LAST_MESSAGE,
    UPDATE_STYLIST_FAVORITES,
    UPDATE_STYLIST_LOOKS
} from './actions';
import { useSelector } from 'react-redux';

const initialState = {
    loading: false,
    stylist: {},
    reviews: [],
    looks: [],
    looksTotal: 0,
    clients: [],
    favorites: [],
    favoritesTotal: 0,
    lastMessage: {},
    error: null
};

export const stylistsReducer = (state = initialState, action) => {
    switch (action.type) {
        case STYLIST_LOADING_START: {
            return {
                ...state,
                loading: true
            };
        }
        case STYLIST_LOAD_START: {
            return {
                ...state,
                stylist: {}
            };
        }
        case SET_STYLIST: {
            const { stylist } = action.payload;
            return {
                ...state,
                stylist
            };
        }
        case SET_STYLIST_REVIEWS: {
            const { reviews } = action.payload;
            return {
                ...state,
                reviews,
                loading: false
            };
        }
        case SET_STYLIST_LOOKS: {
            const { looks } = action.payload;
            return {
                ...state,
                looks: [...state.looks, ...looks.data],
                looksTotal: looks.total_count,
                loading: false
            };
        }
        case UPDATE_STYLIST_LOOKS: {
            const { looks } = action.payload;
            return {
                ...state,
                looks
            };
        }
        case CLEAR_LOOKS: {
            return {
                ...state,
                looks: [],
                looksTotal: 0
            };
        }
        case LOAD_CLIENTS_SUCCESS: {
            const { clients } = action.payload;
            return {
                ...state,
                loading: false,
                clients
            };
        }
        case UPDATE_STYLIST_FAVORITES: {
            const { items, total_count } = action.payload;
            return {
                ...state,
                favorites: items,
                favoritesTotal: total_count
            };
        }
        case UPDATE_LAST_MESSAGE: {
            const { message = {} } = action.payload;
            return {
                ...state,
                lastMessage: message
            };
        }

        case STYLIST_ACTION_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                error,
                loading: false
            };
        }

        default:
            return state;
    }
};

export const name = 'stylists';

export const useStylistsStore = (selector, ...args) =>
    useSelector((store) => selector(store['stylists']), ...args);
