import './style.scss';

import React from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';

import content from '../../../content.json';

const { giftcards } = content;

const Steps = ({ history }) => (
    <Row className="steps">
        <p className="title">{giftcards.steps.title}</p>
        {giftcards.steps.steps.map((step, index) => (
            <Col xs={12} md={4} key={step.text} className="step">
                <div className="num">{index + 1}</div>
                <p>{step.text}</p>
                <Image src={step.img} className="image d-none d-sm-flex" />
            </Col>
        ))}
        <Button
            variant="light"
            onClick={() => history.push('/gift-cards/checkout')}
            className="d-block d-sm-none">
            {giftcards.customers.button}
        </Button>
    </Row>
);

export default Steps;
