import './style.scss';

import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import content from '../../../content.json';

const { giftcards } = content;

const Designs = ({ updateGiftCard }) => {
    const [selected, setSelected] = useState(giftcards.designs.cards[0]);

    useEffect(() => {
        updateGiftCard({ design: selected.value });
    }, [selected]);

    return (
        <Container className="designs" fluid>
            <Row>
                <Col>
                    <p className="title">{giftcards.designs.title}</p>
                </Col>
            </Row>
            <Row>
                <Col key={selected.value} xs={12} className="card-design selected">
                    <div className="icon check" />
                    <ImgWithFallback
                        src={selected.image.src}
                        fallback={selected.image.fallback}
                        className={'fluid-giftcard'}
                    />
                </Col>
                {giftcards.designs.cards
                    .filter((card) => card.value !== selected.value)
                    .map((card) => (
                        <Col key={card.value} xs={6} className="card-design">
                            <a onClick={() => setSelected(card)}>
                                <ImgWithFallback
                                    src={card.image.src}
                                    fallback={card.image.fallback}
                                    className={'fluid-giftcard'}
                                />
                            </a>
                        </Col>
                    ))}
            </Row>
            <Row>
                <Col>
                    <p className="text">{giftcards.designs.text}</p>
                </Col>
            </Row>
        </Container>
    );
};

export default Designs;
