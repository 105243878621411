import './style.scss';

import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { TestimonialsCarousel } from '../../../components';
import ImgWithFallback from '../../../components/ImgWithFallback/ImgWithFallback';
import content from '../../../content.json';

const { giftcards } = content;

const Customers = ({ history }) => (
    <Row className="customers">
        <p className="title">{giftcards.customers.title}</p>
        {giftcards.customers.content.map((customer) => (
            <Col xs={12} md={4} key={customer.name} className="customer d-none d-md-flex">
                <Container>
                    <Row>
                        <Col className="image">
                            <div className="watermark" />
                            <ImgWithFallback
                                src={customer.img.src}
                                fallbackSrc={customer.img.fallback}
                                alt={customer.name}
                                className="img-fluid"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="desc">
                            <p className="text">{customer.text}</p>
                            <p className="name">{customer.name}</p>
                        </Col>
                    </Row>
                </Container>
            </Col>
        ))}
        <Col className="no-padding d-block d-md-none">
            <TestimonialsCarousel items={giftcards.customers.content} />
        </Col>
        <Button
            className="get-gift-btn"
            variant="dark"
            onClick={() => history.push('/gift-cards/checkout')}>
            {giftcards.customers.button}
        </Button>
    </Row>
);

export default Customers;
