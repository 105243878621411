import { rootSplitApi } from 'store/root-api-slice';
import { Message } from './types';
import { getUserId } from 'services/utils/user-utils';


const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['Inbox'] });

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getMessages: build.query<Message[], void>({
			query: () => ({
				url: `proxy/user/${getUserId()}/inbox`,
				method: 'GET'
			}),
			transformResponse: (response: any): Message[] => {
				return response.map((x: any) => {return {...x, isSubscription: x.subscription_id !== null}});
			},
			providesTags: () => [{ type: 'Inbox', id: 'MessagesList' }]
		})
	})
});

export const { useGetMessagesQuery } = extendedApi;