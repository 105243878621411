import './style.scss';

import { useIsMobile, useWithDispatch } from 'hooks';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { setBookingSource } from 'store/common/actions';
import { toggleModal } from 'store/ui/actions';
import { ReviewItem } from 'types/utils';

import content from '../../content.json';

const { howItWorks } = content;

type parentComponentType = 'reviewCard' | 'reviewModal';

interface IReviewBody {
    review: ReviewItem;
    index?: number;
    type?: parentComponentType;
}

interface INameDate {
    parentType: parentComponentType;
}

const ReviewBody: React.FC<IReviewBody> = ({ review, index = 0, type = 'reviewCard' }) => {
    const toggleModalAction = useWithDispatch(toggleModal);
    const setBookingSourceAction = useWithDispatch(setBookingSource);
    const isMobile = useIsMobile();
    const stylistProfileUrl = (stylistUuid: string) => `/stylist/${stylistUuid}/profile`;

    const clickOnStylistEvent = (stylistName: string, stylistUuid: string) => {
        setBookingSourceAction({ source: 'how it works' });
        trackEvent({
            name: MP_EVENTS.HOW_IT_WORKS_STYLIST_CLICK,
            properties: {
                [MP_PROPS.STYLIST_NAME]: stylistName,
                [MP_PROPS.STYLIST_UUID]: stylistUuid
            }
        });
    };
    const changeModalHeight = (modalClass: string, classToAdd: string) => {
        const modal = document.querySelector(modalClass);
        if (modal) {
            const modalElement = modal as HTMLElement;
            modalElement.classList.add(classToAdd);
        }
    };
    const isTextClamped = (elements: NodeListOf<Element>) => {
        elements.forEach((item: Element) => {
            const elem = item as HTMLElement;
            if (elem.scrollHeight > elem.clientHeight) {
                const readMoreElements = document.querySelectorAll('#readMore' + elem.id);
                readMoreElements.forEach((readMoreItem: Element) => {
                    const readMoreElement = readMoreItem as HTMLElement;
                    if (readMoreElement) {
                        readMoreElement.style.visibility = 'visible';
                    }
                });
            }
        });
    };
    const islargeModalComponent = (type: parentComponentType, isMobile: boolean) =>
        type !== 'reviewCard' && !isMobile;

    const ReviewDateNameDetails: React.FC = () => (
        <>
            <span className={'reviewDate'}>{review.date}</span>
            <div className={'name-wrapper'}>
                <span className={'clientName'}>{review.clientName}</span>
            </div>
        </>
    );

    const ReviewNameDateDetails: React.FC = () => (
        <>
            <div className={'name-wrapper'}>
                <span className={'clientName'}>{review.clientName}</span>
            </div>
            <span className={'reviewDate'}>{review.date}</span>
        </>
    );

    const NameDateComponent: React.FC<INameDate> = ({ parentType = 'reviewCard' }) =>
        islargeModalComponent(parentType, isMobile) ? (
            <ReviewNameDateDetails />
        ) : (
            <ReviewDateNameDetails />
        );

    useEffect(() => {
        if (type === 'reviewCard') {
            const elements = document.querySelectorAll('.cardText');
            isTextClamped(elements);
        } else changeModalHeight('.modal-dialog', 'reviewReader-modal');
    }, []);
    return (
        <span className={`${type}`}>
            <div className={'client-date-wrapper'}>
                <NameDateComponent parentType={type} />
            </div>
            <span className="stylistName">
                {howItWorks.reviewsSection.stylistPlaceholder}
                <Link
                    className="black"
                    to={{
                        pathname: stylistProfileUrl(review.stylistUuid),
                        state: { from: window.location.pathname }
                    }}
                    onClick={() => {
                        clickOnStylistEvent(review.stylistName, review.stylistUuid);
                    }}>
                    {review.stylistName}
                </Link>
            </span>
            <span
                className="ratingStar"
                style={{
                    width: parseInt(review.rate) * (type === 'reviewCard' && isMobile ? 28 : 35)
                }}></span>
            <p id={JSON.stringify(index)} className="cardText">
                {review.description}
            </p>
            {type === 'reviewCard' && (
                <span
                    key={index}
                    id={'readMore' + JSON.stringify(index)}
                    onClick={() => toggleModalAction({ type: 'ReadReview', data: review })}
                    className="reviewCard readMore">
                    Continue reading
                </span>
            )}
        </span>
    );
};

export default ReviewBody;
