import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCampaignBooking } from 'store/booking/actions';
import { loadUserMatches } from 'store/common/actions';
import { toggleModal } from 'store/ui/actions';
import Stylist from './Stylist';

const mapStateToProps = ({
    user: { user },
    common: { mixpanelStore }
}) => ({
    user,
    mixpanelStore
});

const mapDispatchToProps = (dispatch) => ({
    loadUserMatches: () => loadUserMatches(dispatch),
    setCampaign: (campaign) => setCampaignBooking(dispatch, campaign),
    toggleModal: (data) => toggleModal(dispatch, data),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Stylist));
