import './style.scss';

import React, { useEffect, useState } from 'react';
import { Accordion, Col, Container, Image, Row } from 'react-bootstrap';
import { sentryException } from 'services/SentryLogging';

import { Loader, ProfilePhoto } from '../../../components';
import content from '../../../content.json';
import { Formatter } from '../../../services';
import Header from './Header';

const texts = content.settings;

export default ({ user, toggleModal, paymentHistory, getPaymentHistory }) => {
    const [loading, setLoading] = useState(true);
    const [collapsed, setCollapsed] = useState();

    useEffect(() => {
        if (user) getSessions();
    }, [user]);

    const getSessions = async () => {
        try {
            setLoading(true);
            await getPaymentHistory();
            setLoading(false);
        } catch (e) {
            setLoading(false);
            sentryException(e, 'Cannot retrieve payment history');
        }
    };

    return (
        <Container className="settings-payment-history" fluid>
            {loading && <Loader />}
            <Row>
                <Header user={user} section="history" />
            </Row>
            <Accordion>
                {paymentHistory?.map((payment) => (
                    <PaymentItem
                        key={payment.session_uuid}
                        item={payment}
                        isCollapsed={collapsed === payment.session_uuid}
                        onCollapse={() =>
                            setCollapsed(
                                collapsed === payment.session_uuid ? null : payment.session_uuid
                            )
                        }
                        onTip={() =>
                            toggleModal({
                                type: 'Tip',
                                data: {
                                    stylist: payment.stylist,
                                    channel_sid: payment.channel_sid
                                }
                            })
                        }
                    />
                ))}
            </Accordion>
        </Container>
    );
};

const PaymentItem = ({ item, onTip, isCollapsed = false, onCollapse }) => (
    <Row className="payment-item">
        <Col>
            <Accordion.Toggle as={Container} eventKey={item.session_uuid} onClick={onCollapse}>
                <Row>
                    <ProfilePhoto user={item.stylist} />
                    <div className={`arrow ${isCollapsed ? 'up' : 'down'} triangle`} />
                    <Col className="payment-info">
                        <label>{`${item.stylist.first_name} ${item.stylist.last_name}`}</label>
                        <label>{Formatter.time(item.created, 'MM/DD/YYYY')}</label>
                    </Col>
                    <Col className="payment-info">
                        <label>{Formatter.price(item.total)}</label>
                        <label className="plan-info">{item.plan_name}</label>
                    </Col>
                </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={item.session_uuid}>
                <Container className="payment-content">
                    <Row>
                        <Col>
                            <label>{item.plan_name}</label>
                            <p>{texts.history.tip}</p>
                        </Col>
                        <Col className="prices">
                            <p>{Formatter.price(item.session_total)}</p>
                            <p>{Formatter.price(item.tip)}</p>
                        </Col>
                    </Row>
                    <Row className="items">
                        <Col>
                            <Image src={texts.history.icons.moodboards} alt="" />{' '}
                            {item.moodboards_count}
                        </Col>
                        <Col>
                            <Image src={texts.history.icons.items} alt="" /> {item.items_count}
                        </Col>
                        <Col>
                            <Image src={texts.history.icons.purcahses} alt="" />{' '}
                            {item.purchased_items_count}
                        </Col>
                        <Col>
                            <Image src={texts.history.icons.cart} alt="" /> {item.cart_items_count}
                        </Col>
                        <label onClick={onTip}>
                            {texts.history.addTip} <div className="arrow right" />
                        </label>
                    </Row>
                </Container>
            </Accordion.Collapse>
        </Col>
    </Row>
);
