import './Header.scss';

import React, { FC } from 'react';
import { Image, Nav, Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import content from '../../../content.json';
import { TProgress } from '../types';

const { logo } = content;

type TProps = {
    progress: TProgress;
};

export const Header: FC<TProps> = ({ progress: { stepIndex, totalSteps, stepTitle } }) => {
    const history = useHistory();

    return (
        <Navbar className="onboarding-header nav-bar">
            {stepIndex > 0 && (
                <Nav.Item className="mobile-back d-flex d-sm-none" onClick={history.goBack} />
            )}

            <Navbar.Brand href="/" className="logo">
                <Image src={logo.black} className="d-flex d-sm-none" />
                <Image src={logo.text_black} className="d-none d-sm-flex" />
            </Navbar.Brand>

            <Nav.Item className="mobile-step-counter d-flex d-sm-none">
                Step {stepIndex + 1} out of {totalSteps}:&nbsp;
                <strong>{stepTitle}</strong>
            </Nav.Item>
        </Navbar>
    );
};
