//import './style.scss';

import React from 'react';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';

interface IStepsProgressBar {
    steps?: Array<any>;
    label: boolean;
    max: number;
    current: number;
    onClick?: (id: number) => void;
    onBack: () => void;
}

const StepsProgressBar: React.FC<IStepsProgressBar> = ({
    steps = [],
    label = false,
    max,
    current,
    onClick,
    onBack
}) => {
    return (
        <Container className="steps-progress-bar">
            {current > 1 && steps && <div className="back-btn" id="back" onClick={onBack} />}
            {steps && (
                <div className="steps">
                    {steps.map((step) => (
                        <div
                            key={step.id}
                            className="step"
                            onClick={() => (onClick ? onClick(step.id) : null)}>
                            <div className={`step-content ${step.id < current ? 'done' : ''}`}>
                                <div className="circle" />
                                <div className="label">{step.text}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <Row>
                <Col>
                    <ProgressBar
                        max={max || steps.length}
                        now={current + 1}
                        label={
                            label && Math.floor((current / max) * 100) > 0
                                ? `${Math.floor((current / max) * 100)}%`
                                : ''
                        }
                        className={label ? 'with-label' : ''}
                        srOnly={label}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default StepsProgressBar;
