import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { login, logout } from 'store/user/actions';

import { loadCart } from '../../redux/reducers/users/actions';
import NavBar from './NavBar';

const mapStateToProps = ({
    user: { user },
    users: { loading, messagesCount, cart, cartTotal, cartPrice },
}) => ({
    loading,
    user,
    messagesCount,
    cart,
    cartTotal,
    cartPrice
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    logout: () => logout(dispatch, ownProps.history),
    login: (credentials) => login(dispatch, credentials),
    loadCart: () => dispatch(loadCart())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
