import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { initMixpanel } from 'services/mixpanel';

import App from './App';
import { Payments } from './services';
import SentryLogging from './services/SentryLogging';
import { store } from 'store';

/*const getEnvFilePath = () => {
    //if (process.env.NODE_ENV === 'production') return `.env.production`;
    return `.env.production`;
};

require('dotenv').config({ path: getEnvFilePath() });*/

if (process.env.NODE_ENV === 'production') {
    TagManager.initialize({ gtmId: 'GTM-PS7PQ25' });

    SentryLogging();
}

initMixpanel();

const stripePromise = loadStripe(Payments.getStripeKey() as string);

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <GoogleOAuthProvider clientId="371897511438-m1ndelauics7s44h16j05t5d7h7is8jl.apps.googleusercontent.com">
            <Elements stripe={stripePromise}>
                <Router>
                    <App />
                </Router>
            </Elements>
        </GoogleOAuthProvider>
    </Provider>);

/*const stripe = require('stripe')(Payments.getStripeKey());
stripe.applePayDomains.create({ domain_name: 'wishi.me' });*/
