import { rootSplitApi } from 'store/root-api-slice';
import { StylistUser } from 'types/stylist';
import { getUserId } from 'services/utils/user-utils';

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['Stylist'] });

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getStylist: build.query<StylistUser, string>({
			query: (stylistId) => ({
				url: `proxy/stylist/${stylistId}/read`,
				method: 'GET',
                params: { user_uuid: getUserId() }
			}),
			
			providesTags: (result) => [{ type: 'Stylist', id: result?.uuid }]
		})
	})
});

export const { useGetStylistQuery } = extendedApi;