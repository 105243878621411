import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import _Quiz from './Quiz';
import _Welcome from './Welcome';

const mapStateToProps = ({
    user: { user },
    booking: { stylist },
    payments: { price }
}) => ({
    user,
    stylist,
    price
});

export const Welcome = withRouter(connect(mapStateToProps)(_Welcome));
export const Quiz = withRouter(connect(mapStateToProps)(_Quiz));
